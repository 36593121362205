<template>
  <div>
    <breadcrumb name="Hotlines Settings" title="Settings" subtitle="Hotlines"/>
    <div class="body">
      <h6>Hotlines</h6>
      <vs-row style="margin: 20px">
        <vs-col vs-lg="11" vs-sm="12" class="settings">
          <vs-input v-model="hotlines.first_number.value" class="input" label="For Opel, Chevrolet & MG Clients" type="number"/>
        </vs-col>
      </vs-row>

      <vs-row style="margin: 20px">
        <vs-col vs-lg="11" vs-sm="12" class="settings">
          <vs-input v-model="hotlines.second_number.value" class="input" label="For Peugeot Clients"
                    type="number"/>
        </vs-col>
      </vs-row>
      <div class="optionBtns">
        <vs-button
          class="mr-5 save"
          icon-pack="feather"
          icon="icon-save"
          :disabled="invalidForm"
          @click="confirmSaveData"
        >Save
        </vs-button>
        <vs-button
          class="ml-5 cancel"
          type="border"
          color="primary"
          @click="getHotlines"
        >Reset
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    components: {Breadcrumb},
    data() {
      return {
        setting: [],
        brands: [],
        data: [],
        hotlines: {
          first_number: {},
          second_number: {}
        },
        line1: "",
        line2: "",
        invalidEmails: false
      };
    },
    methods: {
      getHotlines() {
        this.$vs.loading();
        this.$httpAdmin.get("/config/by-key/find_us_call_number")
          .then(r => {
            this.$vs.loading.close();
            this.hotlines.first_number = r.data.data;

          })
          .catch(() => {
            this.$vs.loading.close();

          });

          this.$vs.loading();
        this.$httpAdmin.get("/config/by-key/2d_find_us_call_number")
          .then(r => {
            this.$vs.loading.close();
            this.hotlines.second_number = r.data.data;

          })
          .catch(() => {
            this.$vs.loading.close();

          });
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData() {
        this.$vs.loading();

        let payload = {
          value: this.hotlines.first_number.value
        }

      this.$httpAdmin.post(`/config/update/${this.hotlines.first_number.id}`, payload)
      .then(r => {
          console.log(r);
          payload = {
            value: this.hotlines.second_number.value
          }
          this.$httpAdmin.post(`/config/update/${this.hotlines.second_number.id}`, payload).
          then(r => {
            console.log(r);
            this.$vs.loading.close();
             this.$vs.notify({
          title: "Saved",
          text: "Data is Saved Successfully",
          color: "primary"
        });
          })
          .catch(e => {
            console.log(e);
            this.$vs.loading.close();
             this.$vs.notify({
            title: "Error",
            text: "Error is occurred!\nCouldn't Save Data!",
            color: "danger"
          });
          })
      })
      .catch(e => {
        this.$vs.loading.close();
        console.log(e);
         this.$vs.notify({
            title: "Error",
            text: "Error is occurred!\nCouldn't Save Data!",
            color: "danger"
          });
      })


      },
    },
    computed: {
      invalidForm() {
        let regex = /^\d+$/;
        if (regex.test(this.hotlines.first_number.value) && regex.test(this.hotlines.second_number.value)) {
          return false;
        }
        return true;
      }
    },
    watch: {},
    mounted() {
      this.getHotlines();
    }
  };
</script>

<style scoped lang="scss">
  .body {
    border-radius: 10px;
    padding: 20px;
  }

  .optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .input {
    width: 100%;
    margin: 0 !important;
  }
</style>
